import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";

const CustomTab = styled(Tab)(({ theme, active }) => ({
  fontStyle: "normal",
  lineHeight: "34px",
  textAlign: "center",
  color: active ? "#F5F5F5" : "#F5F5F5",
  fontSize: "1.2em",
  letterSpacing: "0.15em",
  fontWeight: "300",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8em",
    height: 50,
  },
  marginBottom: 1,
  height: 50,
  backgroundColor: "rgba(0,0,0,0.1)",
}));

const CustomTabs = styled(Tabs)({
  ".MuiTabs-indicator": {
    display: "none",
  },
});

const MediaTabs = () => {
  const location = useLocation();

  return (
    <CustomTabs variant="fullWidth">
      <CustomTab
        label="Video"
        style={{
          marginRight: location.pathname === "/" ? 1 : 0,
          borderRight: location.pathname === "/" ? 0 : "1px solid white",
          borderBottom: location.pathname === "/" ? 0 : "1px solid white",
        }}
        active={location.pathname === "/"}
        href="/"
      />
      <CustomTab
        label="Tom Emmerson"
        style={{
          color: "#F5F5F5",
          flexGrow: 2,
          borderBottom: location.pathname === "/" ? 0 : "1px solid white",
        }}
        href="/"
      />
      <CustomTab
        label="Photo"
        style={{
          marginLeft: location.pathname === "/" ? 1 : 0,
          borderLeft: location.pathname === "/" ? 0 : "1px solid white",
          borderBottom: location.pathname === "/" ? 0 : "1px solid white",
        }}
        href="/photo"
        active={location.pathname === "/photo"}
      />
    </CustomTabs>
  );
};

export default MediaTabs;
