import styled from "@emotion/styled";
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";

const CustomCard = styled(Card)(({ theme }) => ({
  display: "flex",
  backgroundColor: "transparent",
  backgroundImage: "none",
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  flex: "1 0 auto",
  paddingTop: 0,
  textAlign: "center",
}));

const Info = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomCard>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CustomCardContent>
            <Typography variant="h6" component="div">
              Email:{" "}
              <a
                style={{ color: "white" }}
                href="mailto: studio@tomemmerson.com"
              >
                studio@tomemmerson.com
              </a>
            </Typography>
            <Typography variant="h6" component="div">
              Instagram:{" "}
              <a
                style={{ color: "white" }}
                href="https://www.instagram.com/tom.emmerson/"
              >
                @tom.emmerson
              </a>
            </Typography>
          </CustomCardContent>
        </Box>
      </CustomCard>
    </div>
  );
};

export default Info;
