import React, { createContext, useState } from "react";
import { videos } from "../videos";

export const ContentContext = createContext({
  currentVideo: null,
  setCurrentVideo: () => null,
});

export const ContentProvider = ({ children }) => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);

  return (
    <ContentContext.Provider value={{ currentVideo, setCurrentVideo }}>
      {children}
    </ContentContext.Provider>
  );
};
