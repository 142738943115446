import styled from "@emotion/styled";
import { IconButton, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { ContentContext } from "./providers/ContentContext";
import { videos } from "./videos";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  flex: 1,
  ".MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
  minHeight: "8px",

  [theme.breakpoints.down("md")]: {
    minHeight: "16px",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  color: "#F5F5F5",
  fontSize: "1em",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8em",
    lineHeight: "inherit",
    minHeight: 26,
  },
  textTransform: "none",
  minHeight: "8px",
  lineHeight: "16px",
}));

const ContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "rgba(0,0,0,0.1)",
  position: "fixed",
  top: 51,
  [theme.breakpoints.down("md")]: {
    top: 51,
  },
  zIndex: 5,
  width: "100%",
}));

const ContentTabs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const { setCurrentVideo, currentVideo } = useContext(ContentContext);

  const handleChange = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(
    () => setCurrentTab(videos.findIndex((c) => c.name === currentVideo.name)),
    [currentVideo]
  );

  return (
    <ContainerDiv>
      <IconButton
        aria-label="delete"
        onClick={() => {
          setCurrentTab(currentTab - 1);
          setCurrentVideo(videos[currentTab - 1]);
        }}
        disabled={currentTab === 0}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <CustomTabs
        value={currentTab}
        onChange={handleChange}
        aria-label="scrollable force tabs example"
        variant="scrollable"
        scrollButtons={false}
      >
        {videos.map((item) => (
          <CustomTab label={item.name} onClick={() => setCurrentVideo(item)} />
        ))}
      </CustomTabs>
      <IconButton
        aria-label="delete"
        onClick={() => {
          setCurrentTab(currentTab + 1);
          setCurrentVideo(videos[currentTab + 1]);
        }}
        disabled={currentTab === videos.length - 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </ContainerDiv>
  );
};

export default ContentTabs;
