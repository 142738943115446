import "./App.css";
import MediaTabs from "./MediaTabs";
import ContentTabs from "./ContentTabs";
import VideoContent from "./VideoContent";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useContext } from "react";
import { ContentContext } from "./providers/ContentContext";
import { Route, Routes, useLocation } from "react-router-dom";
import PhotoContent from "./PhotoContent";
import { Button } from "@mui/material";
import Info from "./Info";

function App() {
  const currentContent = useContext(ContentContext);
  const location = useLocation();

  return (
    <div className="App">
      <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "1",
          top: 0,
        }}
      >
        <MediaTabs />
      </div>

      <Routes>
        <Route
          path="/"
          element={
            <>
              <ContentTabs />
              <SwitchTransition>
                <CSSTransition
                  key={currentContent.currentVideo.video}
                  addEndListener={(node, done) =>
                    node.addEventListener("transitionend", done, false)
                  }
                  classNames="fade"
                >
                  <VideoContent />
                </CSSTransition>
              </SwitchTransition>
            </>
          }
        ></Route>

        <Route path="photo" element={<PhotoContent />}></Route>
        <Route path="info" element={<Info />}></Route>
      </Routes>

      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: 20,
          zIndex: "1",
          textAlign: "center",
        }}
      >
        <Button
          variant="text"
          style={{ fontSize: "1.6em" }}
          href={location.pathname === "/info" ? "/" : "/info"}
        >
          {location.pathname === "/info" ? "home" : "info"}
        </Button>
      </div>
    </div>
  );
}

export default App;
