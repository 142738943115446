import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
} from "@mui/material";
import { forwardRef, useContext, useRef, useState } from "react";
import { ContentContext } from "./providers/ContentContext";
import CloseIcon from "@mui/icons-material/Close";

import ReactPlayer from "react-player";
import styled from "@emotion/styled";

import { videos } from "./videos";
import { isMacOs, isSafari } from "react-device-detect";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade in ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    backgroundColor: "rgba(0,0,0,0.8)",
    backgroundImage: "none",
    marginTop: 152,
    [theme.breakpoints.down("md")]: {
      marginTop: 152,
    },
    marginLeft: 0,
    marginRight: 0,
    height: "100%",
    width: "100%",
    maxWidth: "100%",
  },
}));

const ExternalVideoDialog = ({ onClose }) => {
  const currentContent = useContext(ContentContext);

  return (
    <CustomDialog
      open={true}
      fullWidth
      keepMounted
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth={false}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {currentContent.currentVideo.name}
        <IconButton
          onClick={onClose}
          style={{ right: 15, position: "absolute" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ReactPlayer
          url={currentContent.currentVideo.link}
          width="100%"
          height="90%"
          playing
          controls="True"
        />
      </DialogContent>
    </CustomDialog>
  );
};

const VideoContent = () => {
  const currentContent = useContext(ContentContext);
  const [showDialog, setShowDialog] = useState(false);

  const refContainer = useRef(null);

  return (
    <div>
      {showDialog && (
        <ExternalVideoDialog onClose={() => setShowDialog(false)} />
      )}
      <video
        ref={refContainer}
        autoPlay
        muted
        playsInline
        loop={showDialog}
        controls={isMacOs && isSafari}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          cursor: "pointer",
        }}
        onClick={() => {
          if (isMacOs && isSafari) {
            // weird mac safari specific stuff
            refContainer.current.currentTime &&
              refContainer.current.paused &&
              setShowDialog(true);
          } else {
            // setShowDialog(true); // Bodge for autoplay thing on mobile
            refContainer.current.currentTime > 1 && setShowDialog(true);
          }
        }}
        onEnded={() =>
          currentContent.setCurrentVideo(
            videos[
              (videos.findIndex(
                (iter) => iter.name === currentContent.currentVideo.name
              ) +
                1) %
                videos.length
            ]
          )
        }
      >
        <source src={currentContent.currentVideo.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoContent;
